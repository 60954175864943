import "./App.scss";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeScreen from "./Components/Screens/HomeScreen";
import AboutScreen from "./Components/Screens/AboutScreen";
import ReelsScreen from "./Components/Screens/ReelsScreen";
import WeddingsScreen from "./Components/Screens/WeddingsScreen";
import ChildrensPartiesScreen from "./Components/Screens/ChildrensPartiesScreen";
import CareHomesScreen from "./Components/Screens/CareHomesScreen";
import TrainingScreen from "./Components/Screens/TrainingScreen";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomeScreen />}></Route>
          <Route path="/about" element={<AboutScreen />}></Route>
          <Route path="/reels" element={<ReelsScreen />}></Route>
          <Route path="/weddings" element={<WeddingsScreen />}></Route>
          <Route
            path="/childrens-parties"
            element={<ChildrensPartiesScreen />}
          ></Route>
          <Route path="/care-homes" element={<CareHomesScreen />}></Route>
          <Route path="/training" element={<TrainingScreen />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
