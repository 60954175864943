import React from "react";
import CustomNavbar from "../Elements/CustomNavbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import SocialMedia from "../Elements/SocialMedia";
import MobileNavbar from "../Elements/MobileNavbar";
import { FaStar } from "react-icons/fa";


function WeddingsScreen() {
  return (
    <div>
      <div className="container-fluid d-flex justify-content-center wedding-background w-100">
        <MobileNavbar />

        <Col
          xs={8}
          className="hide-small-screen-flex vh-100 justify-content-between flex-column "
        >
          <Row className="d-flex justify-content-start w-100">
            <CustomNavbar variant="dark" bg="transparent" />
          </Row>
          <Row className="d-flex w-100">
            <div style={{width:'50%'}} className=" m-auto bg-primary-transparent rounded text-center p-4">
              <p className="lh-base mb-1">
                We booked Hannah for our Jubilee Afternoon Tea party and she was fantastic and the crowd loved her. 
                An absolute delight to deal with and a voice which is awesome!! 
                I highly recommend her for any party as she is so adaptable and is an amazing entertainer!!
                <br/> <span className="fst-italic fw-bold"> - Paul Deany, Clubhouse Manager, Hennerton Golf Club </span>
              </p>
              <span> <FaStar  size={20} color="gold"/> <FaStar size={20} color="gold" /> <FaStar  size={20}  color="gold"/> <FaStar  size={20}  color="gold"/> <FaStar  size={20}  color="gold"/></span>
              <p className="lh-base  mt-5  mb-1">
                Hannah Lebrecht has been performing at the Reading Railway Club since 2018.
                Words are not enough to describe this young lady as to her commitment not only to music #
                and stage presence, but her ability to charm and entertain a wide spread of audience.
                I highly recommend Hannah to anyone who wish to give her a chance to perform in their 
                venues and I wish her every luck possible for the future.
                <br/> <span className="fst-italic  fw-bold">- Lorelei Leaver, Reading Railway Pub</span>
              </p>
              <span> <FaStar  size={20} color="gold"/> <FaStar size={20} color="gold" /> <FaStar  size={20}  color="gold"/> <FaStar  size={20}  color="gold"/> <FaStar  size={20}  color="gold"/></span>
            </div>
            <Image
                  className="m-auto"
                  src="/static/images/wedding_picture.jpg"
                  style={{maxWidth:'410px'}}
                />
          </Row>
          <Row className="d-flex mb-3">
            <SocialMedia variant="dark" />
          </Row>
        </Col>
        <Col
          xs={12}
          md={4}
          className="vh-100 d-flex flex-column desktop-formatting"
        >
          <div className="wedding-box w-100 bg-dark-transparent rounded">
            <div className="my-5">
              <h3 className="box-header text-light text-center">
                Weddings & Gigs
              </h3>
            </div>
            <div>
              <div className="text-start mx-4 pb-5 text-light">
                <p>
                  I love bringing joy and life to any event and would love to come and entertain at your party/wedding/event! 
                </p>
                <p>
                  I am a confident and experienced singer who always strikes up a good rapport with my audience. 
                </p>
                <p>
                  I have experience singing on the streets, at gigs, on stage, in plays, pubs and at concerts. I am friendly, flexible and easy to work with.
                </p>
                <p>
                  I enjoy singing a wide variety of songs and styles, including lyrical ballads, upbeat numbers and many more! I also take requests.
                </p>
                <div className=" show-small-screen pb-2 w-100">
                    <h5 className="fw-bold text-light">Reviews</h5>
                    <p className="lh-base mb-1 text-light">
                      We booked Hannah for our Jubilee Afternoon Tea party and she was fantastic and the crowd loved her. 
                      An absolute delight to deal with and a voice which is awesome!! 
                      I highly recommend her for any party as she is so adaptable and is an amazing entertainer!!
                      <br/> <span className="fst-italic fw-bold"> - Paul Deany, Clubhouse Manager, Hennerton Golf Club </span>
                    </p>
                    <span> <FaStar  size={20} color="gold"/> <FaStar size={20} color="gold" /> <FaStar  size={20}  color="gold"/> <FaStar  size={20}  color="gold"/> <FaStar  size={20}  color="gold"/></span>
                    <p className="lh-base  mt-2  mb-1 text-light">
                      Hannah Lebrecht has been performing at the Reading Railway Club since 2018.
                      Words are not enough to describe this young lady as to her commitment not only to music  ...
                      I wish her every luck possible for the future.
                      <br/> <span className="fst-italic  fw-bold">- Lorelei Leaver, Reading Railway Pub</span>
                    </p>
                    <span> <FaStar  size={20} color="gold"/> <FaStar size={20} color="gold" /> <FaStar  size={20}  color="gold"/> <FaStar  size={20}  color="gold"/> <FaStar  size={20}  color="gold"/></span>
                </div>
                <p>
                  Please do not hesitate to get in touch!
                </p>
                <p>Email me at hannahstar100@hotmail.com for more info!</p>
              </div>
            </div>
            <div>&nbsp;</div>
          </div>
          <div className="show-small-screen-flex mt-4">
            <SocialMedia variant="dark" />
          </div>
        </Col>
      </div>
    </div>
  );
}

export default WeddingsScreen;
