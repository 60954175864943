import React from "react";
import CustomNavbar from "../Elements/CustomNavbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SocialMedia from "../Elements/SocialMedia";
import Image from "react-bootstrap/Image";
import { Container } from "react-bootstrap";
import ImageCard from "../Elements/ImageCard";
import MobileNavbar from "../Elements/MobileNavbar";

function ReelsScreen() {
  const imageCardStyling = {
    width: "200px",
  };

  return (
    <div className="">
      <MobileNavbar />

      <div className="vh-100 container-fluid d-flex reels-background justify-content-center">
        <Col
          xs={12}
          md={7}
          lg={12}
          className="vh-100 d-flex justify-content-between flex-column "
        >
          <Row className="d-flex justify-content-start w-100">
            <CustomNavbar variant="dark" bg="transparent" />
          </Row>
          <Row className=" d-flex justify-content-center align-items-center w-100 g-0">
            <div className=" fluid bg-secondary-transparent rounded reels-box position-relative">
              <h3 className="box-header text-dark text-center pt-2 mt-2 mb-0 mb-lg-5">
                Reels
              </h3>
              <Image
                className="reels-picture"
                style={{ width: "150px" }}
                src="/static/images/reels.png"
              />
              <Container className="mt-3 w-100">
                <Row className="">
                  <Col>
                    <ImageCard
                      styling={{ imageCardStyling }}
                      videoUrl="./static/videos/toothpaste_advert.mp4"
                      bgImage={"./static/images/toothpaste_ad.png"}
                    />
                    <p className="pt-3 text-charcoal">toothpaste advert</p>
                  </Col>
                  <Col>
                    <ImageCard
                      styling={{ imageCardStyling }}
                      videoUrl="./static/videos/waitrose_advert.mp4"
                      bgImage={"/static/images/waitrose_ad.png"}
                    />
                    <p className="pt-3 text-charcoal">waitrose advert</p>
                  </Col>
                  <Col>
                    <ImageCard
                      styling={{ imageCardStyling }}
                      videoUrl="./static/videos/victoria_exercise_video.mp4"
                      bgImage={"/static/images/victoria_studio.png"}
                    />
                    <p className="pt-3 text-charcoal">
                      victoria's exercise studio
                    </p>
                  </Col>
                  <Col>
                    <ImageCard
                      styling={{ imageCardStyling }}
                      videoUrl="./static/videos/theatre_show.mp4"
                      bgImage={"/static/images/theatre_show.png"}
                    />
                    <p className="pt-3 text-charcoal">
                      Toulouse Lautrec performance
                    </p>
                  </Col>
                </Row>
                <Row className="">
                  <Col>
                    <ImageCard
                      styling={{ imageCardStyling }}
                      videoUrl="./static/videos/she_busking.mp4"
                      bgImage={"/static/images/she_image.png"}
                    />
                    <p className="pt-3 text-charcoal">'she' performance</p>
                  </Col>
                  <Col>
                    <ImageCard
                      styling={{ imageCardStyling }}
                      videoUrl="./static/videos/make_you_feel_my_love.mp4"
                      bgImage={"/static/images/busking_image.png"}
                    />
                    <p className="pt-3 text-charcoal">
                      'make you feel my love' performance
                    </p>
                  </Col>
                  <Col>
                    <ImageCard
                      styling={{ imageCardStyling }}
                      videoUrl="./static/videos/nursing_home_singing.mp4"
                      bgImage={"/static/images/nursing_home_singing.png"}
                    />
                    <p className="pt-3 text-charcoal">
                      nursing home performance I
                    </p>
                  </Col>
                  <Col>
                    <ImageCard
                      styling={{ imageCardStyling }}
                      videoUrl="./static/videos/nursing_home_singing_2.mp4"
                      bgImage={"/static/images/nursing_home_singing_2.jpg"}
                    />
                    <p className="pt-3 text-charcoal">
                      nursing home performance II
                    </p>
                  </Col>
                </Row>
                <Row className="">
                  <Col>
                    <ImageCard
                      styling={{ imageCardStyling }}
                      videoUrl="./static/videos/cilit_bang_advert.mp4"
                      bgImage={"/static/images/power_cleaner_ad.png"}
                    />
                    <p className="pt-3 text-charcoal">power cleaner advert</p>
                  </Col>
                  <Col>
                    <ImageCard
                      styling={{ imageCardStyling }}
                      videoUrl="./static/videos/christmas_stocking.mp4"
                      bgImage={"/static/images/christmas_stocking.png"}
                    />
                    <p className="pt-3 text-charcoal">
                       christmas stocking
                    </p>
                  </Col>
                  <Col>
                    <ImageCard
                      styling={{ imageCardStyling }}
                      videoUrl="./static/videos/sausages_sketch.mp4"
                      bgImage={"/static/images/sausages_sketch.png"}
                    />
                    <p className="pt-3 text-charcoal">
                      sausages sketch
                    </p>
                  </Col>
                  <Col>
                    <ImageCard
                      styling={{ imageCardStyling }}
                      videoUrl="./static/videos/love_will_visit_those.mp4"
                      bgImage={"/static/images/love_will_visit_those.png"}
                    />
                    <p className="pt-3 text-charcoal">
                      'love will visit those' performance
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ImageCard
                      styling={{ imageCardStyling }}
                      videoUrl="./static/videos/memory.mp4"
                      bgImage={"/static/images/memory.png"}
                    />
                    <p className="pt-3 text-charcoal">'memory' performance</p>
                  </Col>
                  <Col>
                    <ImageCard
                      styling={{ imageCardStyling }}
                      videoUrl="./static/videos/moonriver_duet.mp4"
                      bgImage={"/static/images/moonriver_duet.jpg"}
                    />
                    <p className="pt-3 text-charcoal">'moon river' duet</p>
                  </Col>
                  <Col>
                    <ImageCard
                      styling={{ imageCardStyling }}
                      videoUrl="./static/videos/supercali.mp4"
                      bgImage={"/static/images/supercali.jpg"}
                    />
                    <p className="pt-3 text-charcoal">supercalifragilisticexpialidocious !</p>
                  </Col>
                </Row>
              </Container>
            </div>
          </Row>
          <Row className="d-flex mb-3">
            <SocialMedia variant="dark" />
          </Row>
        </Col>
      </div>
    </div>
  );
}

export default ReelsScreen;
