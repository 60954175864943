import React, { useState, useEffect } from "react";

function MobileNavbar() {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <div className="show-small-screen-flex mobile-navbar">
        <div className="w-100 d-flex align-items-top justify-content-between">
          <div className="align-self-center ms-3">HANNAH LEBRECHT</div>
          <button
            onClick={() => setShowMenu(!showMenu)}
            class="button-one btn bg-transparent"
            type="button"
            aria-expanded={showMenu}
          >
            <svg
              fill="var(--button-color)"
              class="hamburger"
              viewBox="0 0 100 100"
              width="40"
            >
              <rect
                class="line top"
                width="80"
                height="10"
                x="10"
                y="25"
                rx="5"
              ></rect>
              <rect
                class="line middle"
                width="80"
                height="10"
                x="10"
                y="45"
                rx="5"
              ></rect>
              <rect
                class="line bottom"
                width="80"
                height="10"
                x="10"
                y="65"
                rx="5"
              ></rect>
            </svg>
          </button>
        </div>
        <div
          style={{
            opacity: showMenu ? "1" : "0",
            transition: "all .6s",
            visibility: showMenu ? "visible" : "hidden",
          }}
          className="primary-navigation "
          id="collapseExample"
        >
          {/* <div>
                        &nbsp;
                    </div> */}
          <div className="mt-5">
            <div className="text-start ">
              <a className="text-light text-decoration-none" href="/">
                HOME
              </a>
            </div>
            <div className="text-start my-4">
              <a className="text-light text-decoration-none" href="/about">
                BIO
              </a>
            </div>
            <div className="text-start my-4">
              <a className="text-light text-decoration-none" href="/reels">
                REELS
              </a>
            </div>
            <div className="text-start mt-4 mb-2">
              <div className="txt-light">WORK</div>
            </div>
            <div className="ms-5">
              <div className="text-start">
                <a
                  className="text-lstart text-light text-decoration-none h6"
                  href="/weddings"
                >
                  WEDDINGS & GIGS
                </a>
              </div>
              <div className="text-start">
                <a
                  className="text-light text-decoration-none h6"
                  href="/childrens-parties"
                >
                  CHILDREN'S PARTIES
                </a>
              </div>
              <div className="text-start">
                <a
                  className="text-light text-decoration-none h6"
                  href="/care-homes"
                >
                  CARE HOMES
                </a>
              </div>
              <div className="text-start">
                <a
                  className="text-light text-decoration-none h6"
                  href="/training"
                >
                  MUSIC & DRAMA TRAINING
                </a>
              </div>
            </div>
          </div>
          <div>&nbsp;</div>
        </div>
      </div>
    </>
  );
}

export default MobileNavbar;
