import React, { useState } from "react";
import CustomNavbar from "../Elements/CustomNavbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import SocialMedia from "../Elements/SocialMedia";
import Carousel from "react-bootstrap/Carousel";
import MobileNavbar from "../Elements/MobileNavbar";

function CareHomesScreen() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="min-vh-100 container-fluid d-flex care-homes-background justify-content-center">
      <MobileNavbar />
      <Col
        xs={6}
        className="vh-100 hide-small-screen-flex justify-content-between flex-column"
      >
        <Row className="d-flex justify-content-start w-100">
          <CustomNavbar variant="dark" bg="transparent" />
        </Row>
        <Row className="d-flex desktop-formatting w-100 g-0">
          <div className="bg-primary-transparent w-75 ms-4 position-relative rounded">
            <h3 className="box-header text-dark text-center mx-2 my-3">
              Care Homes
            </h3>
            <Image
            className="p-3 m-auto mb-3 care-homes-picture "
            src="/static/images/care_home_picture.jpg"
          />
            <div style={{ marginRight: "100px"}} className="text-start ms-5 mb-5">
              <p className="care-home-line-height">
                I bring musical character shows to care homes. 
              </p>
              <p className="care-home-line-height">
                I have been performing my shows in care homes across 
                the South East for several years and my shows are full of life and joy!              
              </p>
              <p className="care-home-line-height">
                I play a variety of characters such as Alfie Doolittle and Mary Poppins, as well as own invented characters, 
                such as Dusty the jolly cleaner!
              </p>
              <p className="care-home-line-height">
                The characters come on stage and chat to the residents, singing and dancing as they go! 
              </p>
              <p className="care-home-line-height">
                I sing a wide variety of songs, including musical songs, sing-a-long songs, wartime songs, Elvis, Doris Day, ABBA, 
                The Beatles, The Carpenters, Vera Lynn and many more! 
              </p>
              <p className="care-home-line-height">
                I love performing my shows to the residents; engaging with them, chatting to them, encouraging them to sing along and sometimes even dance.
               It’s lovely to see individuals remembering songs and coming out of themselves! 
              </p>
              <p className="care-home-line-height">
                Please contact me directly for more details.
              </p>
              <p className="care-home-line-height" >Email me at: hannahstar100@hotmail.com</p>

            </div>
          </div>
        </Row>
        <Row className="d-flex mb-3">
          <SocialMedia variant="dark" />
        </Row>
      </Col>
      <Col
        xs={12}
        md={7}
        lg={6}
        className="care-home-right d-flex flex-column desktop-formatting "
      >
        <div  className="show-small-screen-flex">
          &nbsp;
        </div>
        <div className="care-homes-box-mobile bg-primary-transparent w-100 rounded show-small-screen-flex flex-column justify-content-center align-items-center">
          <h3 className="my-5 box-header ">Care Homes</h3>
          <Image
            className="p-3  m-auto mb-3"
            style={{ width: "200px" }}
            src="/static/images/care_home_picture.jpg"
          />
          <div className="text-start mx-3">
            <p>
              I bring musical character shows to care homes. I have been
              performing my shows in care homes across the South East for
              several years and my shows are full of life and joy!
            </p>
            <p>
              I play a variety of characters such as Alfie Doolittle and Mary
              Poppins, as well as own invented characters, such as Dusty the
              jolly cleaner!
            </p>
            <p>
              The characters come on stage and chat to the residents, singing
              and dancing as they go!
            </p>
            <p>
              I sing a wide variety of songs, including musical songs,
              sing-a-long songs, wartime songs, Elvis, Doris Day, ABBA, Vera
              Lynn and many more! I love performing my shows to the residents;
              engaging with them, chatting to them, encouraging them to sing
              along and sometimes even dance. It’s lovely to see individuals
              remembering songs and coming out of themselves! Please contact me
              directly for more details and prices.
            </p>
          </div>
        </div>

        <Image
            variant="dark"
            style={{ width:'40%', marginLeft:'12rem' }}
            src="/static/images/care_home_singing.jpg"
            className="hide-small-screen-flex"
          >

        </Image>
        <Carousel
          variant="dark"
          className="rounded care-homes-reviews"
          activeIndex={index}
          interval={10000}
          onSelect={handleSelect}
        >
          <Carousel.Item>
            <img
              className="review-dimensions"
              src="/static/images/white_background.jpg"
              alt="First slide"
            />
            <Carousel.Caption className="carousel-caption-care-homes">
              <blockquote className="text-dark">
                We are lucky to have Hannah entertain us nearly once a month as
                we celebrate our birthdays. Her repertoire seems endless as she
                sings all our resident’s favourites and even takes requests...
                She interacts with her audience and has a warm and friendly
                manner, and we can’t wait for her next visit.
                <footer>
                  <cite className="text-dark">
                    - Langley Haven Dementia Care Home
                  </cite>
                </footer>
              </blockquote>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="review-dimensions"
              src="/static/images/white_background.jpg"
              alt="Second slide"
            />
            <Carousel.Caption className="carousel-caption-care-homes">
              <blockquote className="text-dark">
                Hannah has been coming to our care home regularly for more than
                four years. Our residents always ask when the young woman who
                changes hats will come again. They sing along and dance with
                Hannah and we’re always happy to see her. A result of her
                professionalism, kindness and good communication skills.
                <footer>
                  <cite className="text-dark"> - The Berkshire Care Home </cite>
                </footer>
              </blockquote>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="review-dimensions"
              src="/static/images/white_background.jpg"
              alt="Third slide"
            />
            <Carousel.Caption className="carousel-caption-care-homes">
              <blockquote className="text-dark">
                ‘Hannah regularly entertains our 35 residents, bringing much joy to them each time.<br/>  She has a lovely voice, 
                varied repertoire and engages with us so well that <br/> soon everyone is singing along <br/> and in some cases <br/> dancing!’
                <footer>
                  <cite className="text-dark"> - Kettlewell House Care Home, Woking' </cite>
                </footer>
              </blockquote>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <div className="show-small-screen-flex">
            <SocialMedia variant="dark" />
        </div>
      </Col>
    </div>
  );
}

export default CareHomesScreen;
