import React from "react";
import CustomNavbar from "../Elements/CustomNavbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import SocialMedia from "../Elements/SocialMedia";
import MobileNavbar from "../Elements/MobileNavbar";

function AboutScreen() {
  return (
    <div className="container-fluid d-flex justify-content-center align-items-center about-background">
      <MobileNavbar />
      <Col
        xs={6}
        className="hide-small-screen-flex vh-100 justify-content-between flex-column "
      >
        <Row className="d-flex justify-content-start w-100">
          <CustomNavbar variant="dark" bg="transparent" />
        </Row>
        <Row
          style={{ marginLeft: "-90px" }}
          className="h-65 d-flex justify-content-end align-items-center w-100 g-0"
        >
          <div
            style={{ marginRight: "-10px" }}
            className=" bg-secondary-transparent d-flex flex-column justify-content-center h-75 w-66 fluid rounded "
          >
            <div className="m-auto w-75">
              <h3 className="box-header text-center mt-3 mb-0">Occasions</h3>
            </div>
            <div className="m-auto w-75">
              <p className="text-center ms-3">
                {" "}
                I am available for performances at
              </p>
              <div className="m-auto w-75">
                <ul className="text-center">
                  <li style={{listStyle:'none'}}><i class="fa-regular fa-star"/>&nbsp;&nbsp;Children’s Parties/Entertainment</li>
                  <li style={{listStyle:'none'}}><i class="fa-regular fa-star"/>&nbsp;&nbsp;Private Parties</li>
                  <li style={{listStyle:'none'}}><i class="fa-regular fa-star"/>&nbsp;&nbsp;Weddings</li>
                  <li style={{listStyle:'none'}}><i class="fa-regular fa-star"/>&nbsp;&nbsp;Care homes and Day Centres</li>
                  <li style={{listStyle:'none'}}><i class="fa-regular fa-star"/>&nbsp;&nbsp;LAMDA (drama) Coaching</li>
                </ul>
              </div>
              <p className="text-center ms-3 mt-3">
                Email me at: hannahstar100@hotmail.com <br /> to find out more
              </p>
            </div>
            <div className="m-auto w-75">&nbsp;</div>
          </div>
        </Row>
        <Row className="d-flex justify-content-start w-100 mb-5">
          <SocialMedia variant="dark" />
        </Row>
      </Col>
      <Col
        xs={12}
        md={7}
        lg={6}
        className="vh-100 d-flex  flex-column align-items-center"
      >
        <div className="about-image-block-mobile mb-3">
          <Image
            className="rounded"
            style={{ width: "250px" }}
            src="/static/images/about_picture.jpg"
          />
        </div>
        <div className="my-auto  fw-bold about-box  bg-primary-transparent box-header text-center position-relative rounded">
          <h3 className="box-header text-center p-2 my-4 ">About Me</h3>
          <Image
            className="about-picture rounded"
            style={{ width: "250px" }}
            src="/static/images/about_picture.jpg"
          />
          <div className="margin-left-about me-3 pb-5 text-start">
            <p>
              My name is Hannah Lebrecht and I am a vivacious actress and singer!
            </p>
            <p>
              I bring musical shows to care homes, run children’s parties, sing at gigs and busk on the streets!
            </p>
            <p>
              I am a confident and spontaneous performer who delights in character acting and audience engagement.
            </p>
            <p>
              I attended Stagecoach from the age of 10, getting involved in the music, dancing, shows and LAMDA exams. 
              I have performed in several musicals, created my own plays and characters and have attended both university 
              and drama school to study performance.
            </p>
            <p>
                Welcome to my page!
            </p>
          </div>
        </div>
        <div className="show-small-screen-flex mt-4">
            <SocialMedia variant="dark" />
        </div>
      </Col>
    </div>
  );
}

export default AboutScreen;
