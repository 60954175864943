import React from "react";
import CustomNavbar from "../Elements/CustomNavbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import SocialMedia from "../Elements/SocialMedia";
import MobileNavbar from "../Elements/MobileNavbar";

function HomeScreen() {
  return (
    <div className="vh-100 container-fluid d-flex justify-content-center align-items-center bg-homescreen">
      <MobileNavbar />
      <div className="show-small-screen-flex mobile-sub-bar ps-3">
        Performer and Singer
      </div>

      <Col
        xs={6}
        className="hide-small-screen-flex vh-100 justify-content-between flex-column "
      >
        <Row className="d-flex ms-3 justify-content-start w-100">
          <CustomNavbar variant="dark" bg="transparent" />
        </Row>
        <Row className="d-flex flex-column align-items-center justify-content-center">
          <div
            style={{ aspectRatio: 4 / 2, borderRadius: "7.5%", zIndex: "200" }}
            className="mt-5 d-flex flex-column justify-content-center align-items-center bg-home-box w-66"
          >
            <h1
              style={{ letterSpacing: "4px" }}
              className="h1 w-auto text-light p-5"
            >
              HANNAH <br /> LEBRECHT
            </h1>
          </div>
          <div
            style={{ aspectRatio: 1 / 1, zIndex: "200" }}
            className="mt-3  rounded-circle d-flex flex-column align-items-center bg-home-circle w-33"
          >
            <div
              style={{ letterSpacing: "4px" }}
              className="h3 m-auto w-100 text-light fw-bold"
            >
              <p className="h3 mb-0">Performer</p> 
              <p className="h3 mb-0">&</p>
              <p className="h3 mb-0">Singer</p>
            </div>
          </div>
        </Row>
        <Row>&nbsp;</Row>
      </Col>
      <Col className="d-flex  justify-content-center-mobile" xs={12} lg={6}>
        <div className="d-flex flex-column align-items-center mt-lg-5">
          {" "}
          <div>
            <Image
              className="profile-picture"
              fluid
              src="/static/images/profile_picture.png"
            />
          </div>
          <div className="bg-primary rounded-pill  mt-5 mt-lg-4">
            <SocialMedia variant="dark" />
          </div>
        </div>
      </Col>
    </div>
  );
}

export default HomeScreen;
