import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

function CustomNavbar({ variant, bg }) {
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  return (
    <div className="hide-small-screen">
      <Navbar bg={bg} variant={bg}>
        <Container fluid>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px", color: "white !important" }}
              navbarScroll
              variant="dark"
            >
              <Nav.Link className={"link-" + variant} href="/">
                HOME
              </Nav.Link>
              <Nav.Link className={"link-" + variant} href="/about">
                BIO
              </Nav.Link>
              <Nav.Link className={"link-" + variant} href="/reels">
                REELS
              </Nav.Link>
              <NavDropdown
                bg={bg}
                title={<span className={"my-auto text-" + variant}>WORK</span>}
                id="navbarScrollingDropdown"
                show={show}
                onMouseEnter={showDropdown}
                onMouseLeave={hideDropdown}
              >
                <NavDropdown.Item className="link-light" href="/weddings">
                  WEDDINGS & GIGS
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="link-light"
                  href="/childrens-parties"
                >
                  CHILDREN'S PARTIES
                </NavDropdown.Item>
                <NavDropdown.Item className="link-light" href="/care-homes">
                  CARE HOMES
                </NavDropdown.Item>
                <NavDropdown.Item className="link-light" href="/training">
                  MUSIC & DRAMA TRAINING
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default CustomNavbar;
