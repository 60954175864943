import React, { useState } from "react";
import {
  FaYoutube,
  FaFacebookF,
  FaInstagram,
  FaEnvelope,
  FaCopy,
} from "react-icons/fa";
import OutsideClickHandler from "react-outside-click-handler";

function SocialMedia({ variant }) {
  const [clipboardHover, setClipboardHoverState] = useState(false);
  return (
    <div
      style={{ height: "55px" }}
      onMouseLeave={() => setClipboardHoverState(false)}
      className="d-flex align-items-baseline"
    >
      <div style={{ width: "300px" }} className="my-1">
        {clipboardHover ? (
          <p className={"text-" + variant}>hannahstar100@hotmail.com</p>
        ) : (
          <>
            <a
              href="https://www.youtube.com/channel/UCOrwgP2bOpF8YDuRvE14kFA"
              target={"_blank"}
              className={
                "link-" +
                variant +
                " ms-3 me-5 social-media-button btn btn-link  p-2 rounded-circle"
              }
            >
              <FaYoutube size={28} />
            </a>
            <a
              href="https://www.facebook.com/hlebrecht"
              target={"_blank"}
              className={
                "link-" +
                variant +
                " me-5 social-media-button btn btn-link  p-2 rounded-circle"
              }
            >
              <FaFacebookF size={28} />
            </a>
            <a
              href="https://www.instagram.com/hannahlebrecht92/"
              target={"_blank"}
              className={
                "link-" +
                variant +
                " me-5 social-media-button btn btn-link  p-2 rounded-circle"
              }
            >
              <FaInstagram size={28} />
            </a>
          </>
        )}
      </div>
      <div
        className="my-1 me-3"
        onMouseLeave={() => setClipboardHoverState(false)}
      >
        <OutsideClickHandler
          onOutsideClick={() => setClipboardHoverState(false)}
        >
          <button
            title="Click to Copy"
            onMouseEnter={() => {
              setTimeout(() => setClipboardHoverState(true), 300);
            }}
            onClick={() => {
              navigator.clipboard.writeText("hannahstar100@hotmail.com");
            }}
            className={
              " btn btn-link text-" +
              variant +
              " social-media-button p-2 rounded-circle"
            }
          >
            {" "}
            {clipboardHover ? <FaCopy size={28} /> : <FaEnvelope size={28} />}
          </button>
        </OutsideClickHandler>
      </div>
    </div>
  );
}

export default SocialMedia;
