import React, { useState } from "react";
import Image from "react-bootstrap/Image";
import ModalVideo from "react-modal-video";

function ImageCard({ styling, bgImage, videoUrl }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <div onClick={() => setOpen(true)} className="image-container m-auto">
        <Image style={styling.width} src={bgImage} className="image-card" />
      </div>
      <ModalVideo
        channel="custom"
        autoplay
        isOpen={isOpen}
        url={videoUrl}
        onClose={() => setOpen(false)}
    
      />
    </>
  );
}

export default ImageCard;
