import React from "react";
import CustomNavbar from "../Elements/CustomNavbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SocialMedia from "../Elements/SocialMedia";
import MobileNavbar from "../Elements/MobileNavbar";
import Image from "react-bootstrap/Image";

function TrainingScreen() {
  return (
      <div className="container-fluid d-flex justify-content-center training-background">
        <MobileNavbar />
        <Col
          xs={6}
          className="hide-small-screen-flex vh-100  justify-content-between flex-column "
        >
          <Row className="d-flex justify-content-start w-100">
            <CustomNavbar variant="dark" bg="transparent" />
          </Row>
          <Row className="d-flex justify-content-center">
            <Image
              style={{ marginLeft: "-100px" }}
              className="w-75"
              src="/static/images/lamda.jpg"
            />
          </Row>
          <Row className="d-flex mb-3">
            <SocialMedia variant="dark" />
          </Row>
        </Col>
        <Col
          xs={12}
          md={7}
          lg={6}
          className="vh-100 d-flex flex-column desktop-formatting margin-top-mobile"
        >
          <div className="w-100 overflow-auto training-block bg-dark-transparent rounded flex-column d-flex pb-4 me-lg-5">
            <h3 className="box-header p-2 mx-2 my-4 text-light text-center">
              LAMDA Training
            </h3>
            <div className="mx-4 text-light text-start">
              <h6 className="fw-bold">What I Offer</h6>
              <p>
                I offer 1-1 and 1-2 LAMDA coaching for children aged between
                5-16 years. I teach for exams in Acting, Verse and Prose and
                Musical Theatre.
              </p>
              <h6 className="fw-bold">My Experience</h6>
              <p>
                I have a LAMDA Teachers Certificate in Communication as a
                teaching qualification. I passed with Distinction
                <ul>
                  <li>
                    I have previous experience coaching children for ‘Acting’
                    and ‘Verse and Prose’ exams. I can also now offer Musical
                    Theatre training. Most students I taught have achieved
                    either Merit or Distinction in their exams
                  </li>
                  <li>
                    I have taken LAMDA exams up to Grade 8 myself and absolutely
                    loved the process! This helps me to understand the process
                    as well as the exams and coach the children through it
                    better.
                  </li>
                  <li>
                    I thoroughly enjoy working 1-1 with students, coaching them
                    through the process, identifying their skills, helping them
                    overcome their difficulties and challenging them to reach
                    their potential
                  </li>
                  <li>
                    I love working with children and have taught drama at after
                    school clubs, a Saturday school and also through running
                    kids parties
                  </li>
                  <li>
                    I care about the individual and focus on developing them
                    according to their particular learning styles.
                  </li>
                </ul>
              </p>
              <h6 className="fw-bold ">Why LAMDA?</h6>
              <p >
                Whether or not your child loves drama, LAMDA exams help to build
                confidence, they help to develop communication skills; they help
                a child to express themselves physically, emotionally and
                verbally and they help to develop imagination and creativity.
                <br/>
              </p>
              <p className="text-center" >Email me at: hannahstar100@hotmail.com to find out more.</p>
            </div>
          </div>
          <div className="show-small-screen-flex">
            <SocialMedia variant="dark" />
          </div>
        </Col>
      </div>
  );
}

export default TrainingScreen;
