import React, { useState } from "react";
import CustomNavbar from "../Elements/CustomNavbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import SocialMedia from "../Elements/SocialMedia";
import Carousel from "react-bootstrap/Carousel";
import MobileNavbar from "../Elements/MobileNavbar";
import { FaStar } from "react-icons/fa";

function ChildrensPartiesScreen() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div>
      <MobileNavbar />
      <div className=" container-fluid d-flex  justify-content-center childrens-background">
        <Col
          xs={6}
          className="vh-100 hide-small-screen-flex  justify-content-between flex-column "
        >
          <Row className="d-flex justify-content-start w-100">
            <CustomNavbar variant="dark" bg="transparent" />
          </Row>
          <Row className="h-75 d-flex justify-content-center align-items-center w-100 g-0">
            <div className="bg-primary-transparent w-75 ms-4 position-relative rounded">
              <div className="d-flex justify-content-center">
                <div>
                  <Image
                    className=""
                    src="/static/images/magic_hat.png"
                    width={100}
                  />
                </div>  
                <h3 className="box-header text-center my-4">Children's Parties</h3>
              </div>
              <div className=" mt-3 mb-5 mx-5 text-start">
                <p className="mb-0">
                  {" "}
                  I run children’s parties and love seeing the kids energised
                  and well entertained!
                </p>
                <p className="mb-0">
                  {" "}
                  I have been working as an entertainer for the past two years
                  and have loved it. I have worked with companies such as
                  ‘Bubble Pop Productions’ and ‘The Enchanted Players Theatre
                  Company’, as well as on my own.
                </p>
                <p className="mb-0">
                  {" "}
                  The parties I run are for children aged between 1 and 10 and
                  can be any length of time up to 2 hours long.
                </p>
                <p className="mb-0">
                  {" "}
                  My parties are full of fun and are tailored to the individual.
                  I offer a range of entertainment,<span className="fw-bold"> including music, dancing, games,
                parachute games, balloon modelling, a magic show, glitter tattoos, bubbles </span>
                 and more! I can come dressed as your favourite
                  character/princess, or just as myself!
                </p>

                <p className="mb-0 text-center"> Please do not hesitate to get in touch for more details!</p>
                <p className="text-center">Email me at: hannahstar100@hotmail.com to find out more.</p>
                <Image
                    className="position-absolute bear-picture"
                    src="/static/images/bear_and_balloon.png"
                    width={250}/>
              </div>
            </div>
          </Row>
          <Row className="d-flex mb-3">
            <SocialMedia variant="dark" />
          </Row>
        </Col>
        <Col
          xs={12}
          md={7}
          lg={6}
          className="vh-100 d-flex flex-column desktop-formatting-childrens childrens-picture"
        >
          <div
            style={{ marginTop: "80px" }}
            className="mb-4  bg-primary-transparent w-100 rounded flex-column show-small-screen-flex">
            <h3 className="pt-3 mb-0 box-header ">Children's Parties</h3>
            <div className="text-start m-3">
              <p>
                {" "}
                I run children’s parties and love seeing the kids energised and
                well entertained!
              </p>
              <p>
                {" "}
                I have been working as an entertainer for the past two years and
                have loved it. I have worked with companies such as ‘Bubble Pop
                Productions’ and ‘The Enchanted Players Theatre Company’, as
                well as on my own.
              </p>
              <p>
                {" "}
                The parties I run are for children aged between 1 and 10 and can
                be any length of time up to 2 hours long.
              </p>
              <p>
                {" "}
                My parties are full of fun and are tailored to the individual. I
                offer a range of entertainment, including <span className="fw-bold"> music, dancing, games,
                parachute games, balloon modelling, a magic show, glitter tattoos, bubbles</span>
                &nbsp;and more! I can come dressed as your favourite
                character/princess, or just as myself!
              </p>
              <p> Please do not hesitate to get in touch for more details!</p>
            </div>
          </div>
          <Carousel
            variant="dark"
            style={{ height: "230px" }}
            className="children-picture-width mb-5"
            activeIndex={index}
            interval={2000}
            onSelect={handleSelect}
          >
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/static/images/childrens_profile.jpg"
                alt="robin hood"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/static/images/princess_pic.png"
                alt="princess"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/static/images/story_picture.png"
                alt="reading"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/static/images/fairy_picture.jpg"
                alt="fairy picture"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/static/images/warrior.jpg"
                alt="warrior pose"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/static/images/superhero.jpg"
                alt="super hero"
              />
            </Carousel.Item>
          </Carousel>
          <div className="children-reviews-box bg-secondary-transparent p-4 rounded">
            <p className="lh-base mb-1">
                ‘You did a smashing job despite the last minute changes! All the parents were
                raving about you! Lots of compliments! {/*They adored the way you communicated
                 and entertained all the kids despite the l1arge age range! You were so flexible
                despite all the distractions and  all the kids felt so special The kids and parents
                cannot stop complimenting what a great job you did. My partner couldn&#39;t even
                believe you stepped in last minute &quot;well no one could&#39;ve done it better!&quot;
                I&#39;m incredibly grateful at your kindness esp towards the children. You made it all
                about them and thats exactly what I wanted. */} ... Cannot thank you enough.
                Absolutely pitch perfect!!’ - Sina
              </p>
              <span> <FaStar  size={20} color="gold"/> <FaStar size={20} color="gold" /> <FaStar  size={20}  color="gold"/> <FaStar  size={20}  color="gold"/> <FaStar  size={20}  color="gold"/></span>
              <p className="lh-base  mt-2  mb-1">
                    ‘Hannah was absolutely brilliant!! She absolutely smashed it!!! Thank you very
                    much. Very happy kids.’
              </p>
              <span> <FaStar  size={20} color="gold"/> <FaStar size={20} color="gold" /> <FaStar  size={20}  color="gold"/> <FaStar  size={20}  color="gold"/> <FaStar  size={20}  color="gold"/></span>
              <p className="lh-base  mt-2  mb-1">
                    ‘Hannah made the party so much fun for the varying ages of the group. She played games, did some magic, hosted an impromptu karaoke session and chatted with all the parents.’
              </p>
              <span> <FaStar  size={20} color="gold"/> <FaStar size={20} color="gold" /> <FaStar  size={20}  color="gold"/> <FaStar  size={20}  color="gold"/> <FaStar  size={20}  color="gold"/></span>
              <p className="lh-base  mt-2  mb-1">
                ‘We had Hannah as our entertainer who was really lovely, fantastic with all the children and kept them occupied the whole time.’ - Madeline
              </p>
              <span> <FaStar  size={20} color="gold"/> <FaStar size={20} color="gold" /> <FaStar  size={20}  color="gold"/> <FaStar  size={20}  color="gold"/> <FaStar  size={20}  color="gold"/></span>

          </div>
          <div className="show-small-screen-flex mt-4">
            <SocialMedia variant="dark" />
          </div>
        </Col>
      </div>
    </div>
  );
}

export default ChildrensPartiesScreen;
